import React from "react";
import { cilBan, cilStar } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import moment from "moment";
import { motion } from "framer-motion";
import "../styles/boletoStyle.css";
import { subirImagenesBatch } from "./Acciones";
import { mostrarMensajeError, mostrarMensajeExito } from "./Mensajes";
import "moment/locale/es";
import { size } from "lodash";

export function formatearFechaTimestampt(params) {
  if (params.value && params.value.seconds) {
    const fechaTimestamp = moment.unix(params.value.seconds);

    const fechaFormateada = fechaTimestamp.format("DD/MM/YYYY HH:mm:ss");
    return fechaFormateada;
  } else {
    return "";
  }
}

export function formatearNumeroComoDinero(params) {
  if (typeof params.value === "number") {
    const formattedValue = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(params.value);

    return formattedValue;
  } else {
    return params.value;
  }
}

export function alinearDerecha(params) {
  return {
    textAlign: "right",
  };
}

export function formatearRating(params) {
  if (typeof params.value === "number") {
    const ratingValue = Math.round(params.value);

    const maxStars = Math.min(5, ratingValue);

    const stars = Array.from({ length: maxStars }, (_, index) => (
      <CIcon key={index} icon={cilStar} className="text-warning" />
    ));

    const iconToShow =
      ratingValue > 0 ? stars : <CIcon icon={cilBan} className="text-danger" />;

    return iconToShow;
  } else {
    return params.value;
  }
}

export function formatearTextoAMayusculas(params) {
  if (params.value && typeof params.value === "string") {
    return params.value.toUpperCase();
  }
  return params.value;
}

export function createDataDocumentacion(formData, fieldNames, headerNames) {
  const data = fieldNames.map((fieldName, index) => ({
    campo: fieldName,
    url: formData[fieldName],
    headerName: headerNames[index],
  }));
  return data;
}

export function OpenInGoogleMaps(lat, lng) {
  const mapsURL = `https://www.google.com/maps?q=${lat},${lng}`;
  window.open(mapsURL, "_blank");
}

export function generarUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const convertirFicheroBlob = async (path) => {
  try {
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function () {
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", path, true);
      xhr.send(null);
    });

    return blob;
  } catch (error) {
    mostrarMensajeError(
      "Error al cargar archivo",
      "Hubo un problema al cargar el archivo. Por favor, inténtalo de nuevo más tarde."
    );
  }
};

export const generarBoletos = (
  inicio,
  fin,
  selectedTickets,
  handleTicketSelection,
  boletosDisabled,
  numeroBuscado,
  scrollRealizado,
  setScrollRealizado,
  filtro = "DISPONIBLES",
  digitosBoleto = 6
) => {
  const boletos = [];
  for (let i = inicio; i <= fin; i++) {
    const ticketNumber = String(i).padStart(digitosBoleto, "0");
    const isSelected = selectedTickets.includes(ticketNumber);
    const isDisabled = boletosDisabled?.some((item) =>
      item.boletos.includes(ticketNumber)
    );
    const backgroundColor = isDisabled
      ? "lightgray"
      : isSelected
      ? "lightblue"
      : "";

    const isNumeroBuscado = numeroBuscado && ticketNumber === numeroBuscado;

    if (
      (filtro === "DISPONIBLES" && isDisabled) ||
      (filtro === "NO_DISPONIBLES" && !isDisabled)
    ) {
      continue;
    }

    boletos.push(
      <motion.div
        key={i}
        id={isNumeroBuscado ? "numero-buscado" : ""}
        onClick={() => {
          if (isDisabled) {
            mostrarMensajeError(
              "No disponible",
              "Este boleto no se encuentra disponible."
            );
          } else {
            handleTicketSelection(ticketNumber);
          }
        }}
        className={isSelected ? "rainbow" : "boletoDisponible"}
        whileHover={{
          rotate: 360,
          transition: { duration: 0.3, ease: "easeInOut" },
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor,
          padding: "10px",
          flexDirection: "column",
          borderRadius: "10px",
          width: "100px",
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
      >
        <h5 className="numeros">{ticketNumber}</h5>
      </motion.div>
    );
  }

  if (numeroBuscado && !scrollRealizado) {
    setTimeout(() => {
      const elementoBuscado = document.getElementById("numero-buscado");
      if (elementoBuscado) {
        elementoBuscado.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setScrollRealizado(true);
      }
    }, 0);
  }

  return boletos;
};

export const formatearImagenes = async (imagenes, coleccion) => {
  const imagenesNoSubidas = [];
  const imagenesSubidas = [];

  for (const imagen of imagenes) {
    if (imagen?.startsWith("https://firebasestorage.googleapis.com")) {
      imagenesSubidas.push(imagen);
    } else {
      imagenesNoSubidas.push(imagen);
    }
  }

  const imagenesSubidasFirebase = await subirImagenesBatch(
    imagenesNoSubidas,
    coleccion
  );

  const imagenesFormateadas = [...imagenesSubidas, ...imagenesSubidasFirebase];

  return imagenesFormateadas;
};

export const formatDateWithText = (timestamp) => {
  const seconds = timestamp?.seconds;
  const date = new Date(seconds * 1000 + timestamp?.nanoseconds / 1000000);

  return moment(date).format("LLLL");
};

export const enviarMensajeWhatsApp = (numero, mensaje) => {
  window.open(
    `https://wa.me/${numero}?text=${encodeURIComponent(mensaje)}`,
    "_blank"
  );
};

export const enviarCorreoElectronico = (correo, asunto, cuerpo) => {
  window.open(
    `mailto:${correo}?subject=${encodeURIComponent(
      asunto
    )}&body=${encodeURIComponent(cuerpo)}`,
    "_blank"
  );
};

export const realizarLlamadaTelefonica = (numero) => {
  window.open(`tel:${numero}`, "_blank");
};

export const calcularPorcentajeBoletosVendidos = (
  boletosVendidos,
  boletosDisponibles
) => {
  if (boletosDisponibles === 0 || boletosVendidos === 0) {
    return 0;
  }
  return (boletosVendidos / boletosDisponibles) * 100;
};

export const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = size(shuffledArray) - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const calculateRemainingTime = (fechaApartado, limiteHoras) => {
  const fechaApartadoMillis = fechaApartado.getTime();
  const fechaActualMillis = Date.now();
  const diffMillis = fechaApartadoMillis - fechaActualMillis;
  const diffSeconds = Math.abs(diffMillis) / 1000;
  let tiempoRestante = limiteHoras * 3600 - diffSeconds;
  tiempoRestante = tiempoRestante < 0 ? 0 : tiempoRestante;
  const horas = Math.floor(tiempoRestante / 3600);
  const minutos = Math.floor((tiempoRestante % 3600) / 60);
  const segundos = Math.floor(tiempoRestante % 60);
  return {
    horas,
    minutos,
    segundos,
  };
};

export function obtenerUbicacionActual() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const respuesta = await fetch(
              `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=es`
            );
            const datos = await respuesta.json();
            resolve(datos.principalSubdivision);
          } catch (error) {
            reject(error);
          }
        },
        (error) => {
          // reject(error);
          resolve(null);
        }
      );
    } else {
      reject(
        new Error("La geolocalización no está soportada por este navegador.")
      );
    }
  });
}

export const validarAcceso = (proveedor, usuarioID) => {
  return proveedor === usuarioID;
};

export function calcularDiasRenovacionMembresia(fechaRenovacionMembresia) {
  if (!fechaRenovacionMembresia) {
    return 0;
  }

  const fechaRenovacion = new Date(fechaRenovacionMembresia.seconds * 1000);
  const diferenciaDias = Math.ceil(
    (fechaRenovacion - new Date()) / (1000 * 60 * 60 * 24)
  );

  return Math.max(diferenciaDias, 0);
}

export const validateURL = (url) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  let newErrors = {};

  if (url && !urlRegex.test(url)) {
    newErrors = {
      ...newErrors,
      url: "El enlace proporcionado no es una URL válida",
    };
  }

  return newErrors;
};

export const handleCopyText = (value) => {
  if (value) {
    navigator.clipboard.writeText(value);
    mostrarMensajeExito(
      "Valor copiado",
      `El valor "${value}" se ha copiado correctamente.`
    );
  }
};

export function formatName(name, shouldRevealFullName) {
  if (shouldRevealFullName) {
    return name;
  } else {
    const namesArray = name.split(" ");
    const formattedNames = namesArray.map((name, index) => {
      if (index === 0) {
        return name;
      } else {
        return name.charAt(0) + ".";
      }
    });
    return formattedNames.join(" ");
  }
}

export const isEmbeddedBrowser = () => {
  const ua = navigator.userAgent || window.opera;
  return /FBAN|FBAV|Instagram/.test(ua);
};

export function normalizarTexto(texto) {
  if (typeof texto !== "string" || texto.trim() === "") {
    return "";
  }

  return texto
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9\s]/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    .toUpperCase();
}

export function generarPalabrasClave(texto) {
  const textoNormalizado = normalizarTexto(texto);

  if (!textoNormalizado) return [];

  const palabras = textoNormalizado.split(" ");
  const palabrasClave = new Set();

  palabras.forEach((palabra) => {
    let subcadena = "";
    for (const letra of palabra) {
      subcadena += letra;
      palabrasClave.add(subcadena);
    }
  });

  return Array.from(palabrasClave);
}
